<div class="row justify-content-center align-items-center m-0">
    <div class="col-12 col-md-8 d-flex justify-content-center text-center">
        <figure>
            <img i18n-alt src="assets/svg/lock.svg" alt="Bezpečná platba kryptoměnou"/>
            <figcaption i18n class="fa-bold">Bezpečné odeslání platby</figcaption>
        </figure>
    </div>
</div>
<div class="row justify-content-center text-center p-0 m-0" [attr.data-txtype]="txState">
    <div class="col-12 col-md-7 col-lg-9 terminalForm">
        <div class="my-1 my-md-4 px-1">
            <terminal-progress></terminal-progress>
        </div>
        <div class="panel">
            <div class="panel-body">
                <!-- Terminal-->
                <terminal-form 
                [page]="page"
                [progressBarColor]="progressBarColor"
                [progressBar]="progressBar"
                (OnFormChanged)="onFormChanged($event)"
                (AuthorizationRequired)="onAuthorizationRequired($event)"
                ></terminal-form>
            </div>
            <div class="panel-footer">
                <div id="footer-currencies" class="row mt-4">
                    <div class="col-8 text-start">
                        <section class="coins">
                            <span i18n class="text-silver mr-1">Přijímáme platby těmito měnami</span>
                            <nav *ngFor="let item of footerCurrencies" class="d-inline-block">
                                <ng-container *ngIf="item.isEnable; else disabled">
                                    <p title="{{item.inCryptoCurrencyName}}"><span class="cc {{item.shortName}}"></span></p>
                                </ng-container>
                                <ng-template #disabled>
                                    <p class="disabled" title="{{item.inCryptoCurrencyName}}"><span class="cc {{item.shortName}}"></span></p>
                                </ng-template>
                            </nav>
                        </section>
                    </div>
                    <div class="col-4 d-flex justify-content-end align-items-center">
                        <widget-course [course]="countedCourse"></widget-course>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="align-items-start col d-md-flex d-none">
                        <figure>
                            <img class="logo" src="assets/images/hdcrypto_b_w.png" alt="HD Crypto s.r.o" title="HD Crypto s.r.o"/>
                        </figure>
                    </div>
                    <div class="
                        align-items-center 
                        align-items-end 
                        col 
                        d-flex 
                        justify-content-between">
                        <div class="d-inline-block flex">
                            <button class="align-items-center d-flex justify-content-center mdc-icon-button" [matMenuTriggerFor]="menu">
                                <span>{{langName}}</span>
                                <!-- <span class="flag-icon flag-icon-{{lang}} flag-icon-squared"></span> -->
                                <mat-icon class="text-silver">expand_more</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <ng-container *ngIf="menuItems !== null">
                                    <div *ngFor="let item of menuItems">
                                        <a [href]="'/' + item.Abbreviation" mat-menu-item >
                                            <span [class]="{'active': item.Abbreviation === _localization}">
                                                {{item.CountryName}} <span class="flag-icon flag-icon-{{item.Abbreviation}} flag-icon-squared"></span>
                                            </span>
                                        </a>
                                    </div>
                                </ng-container>
                            </mat-menu>
                        </div>
                        <a class="d-inline-block" href="https://ticket.hdcrypto.cz/open.php" target="_blank" i18n>Podpora</a>
                        <a class="d-inline-block" href="https://hdcrypto.cz/how-to-buy-cryptocurrencies" target="_blank"i18n>Často kladené dotazy</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>