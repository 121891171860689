import { APP_ID, DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagesRoutingModule } from './pages-routing.module';
import { AdminComponent } from './admin/admin.component';
import { TerminalComponent } from './terminal/terminal.component';
import { HomeComponent } from './home/home.component';
import { WelcomeComponent } from '../components/welcome/welcome.component';
import { TerminalFormComponent } from '../components/terminalForm/terminalForm.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { QrScanComponent } from './qrscan/qrscan.component';
import { FormsModule, NG_VALIDATORS, ReactiveFormsModule } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCommonModule, MatOptionModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatStepperModule } from '@angular/material/stepper';
import { ProgressComponent } from '../components/progress/progress.component';
import { MatMenuModule } from '@angular/material/menu';
import { EshopComponent } from './eshop/eshop.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DetailFormComponent } from '../components/detailForm/detailForm.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { InvoiceComponent } from './invoice/invoice.component';
import { CourseWidgetComponent } from '../components/courseWidget/courseWidget.component';
import { ChangeIconDirective } from '../directives/changeicon.directive';
import { DevOnlyDirective } from '../directives/devonly.directive';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { LegalComponent } from '../components/legalComponent/legal.component';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogContentComponent } from '../components/enterInfoModal/dialog-content.component';
import { FileResultComponent } from '../components/fileResultComponent/file-result/file-result.component';
import { RouterOutlet } from '@angular/router';
import { NavigationBarComponent } from '../components/navigation-bar/navigation-bar.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PagesComponent } from './pages.component';
import { NotFoundComponent } from './notfound/notfound.component';
import { ChangeClassDirective } from '../directives/show.directive';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
    declarations: [
        NotFoundComponent,
        ChangeClassDirective,
        PagesComponent,
        NavigationBarComponent,
        HomeComponent,
        AdminComponent,
        TerminalComponent,
        WelcomeComponent,
        TerminalFormComponent,
        DevOnlyDirective,
        QrScanComponent,
        ProgressComponent,
        EshopComponent,
        DetailFormComponent,
        InvoiceComponent,
        CourseWidgetComponent,
        ChangeIconDirective,
        LegalComponent,
        DialogContentComponent,
        FileResultComponent,
    ],
    imports: [
        //BrowserAnimationsModule,
        CommonModule,
        RouterOutlet,
        PagesRoutingModule,
        MatGridListModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatButtonModule,
        MatCommonModule,
        MatMenuModule,
        MatFormFieldModule,
        MatSnackBarModule,
        MatToolbarModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        MatCheckboxModule,
        MatStepperModule,
        MatProgressBarModule,
        MatSlideToggleModule,
        ClipboardModule,
        MatProgressSpinnerModule,
        MatCardModule,
        MatDialogModule
    ]
})
export class PagesModule { }