import { Component, EventEmitter, inject, Inject, Input, LOCALE_ID, NgZone, numberAttribute, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DOCUMENT, Location } from '@angular/common';
import { environment } from '../../environments/environment';
import { IBankAccount } from '../../models/banks.model';
import { CourseWidget } from '../../models/course.model';
import { CurrencyDtoOut, FiatCurrencyDtoOut } from '../../models/currency.model';
import { IEnvironment } from '../../models/environment.model';
import { MerchantInfoModel, MerchantModel } from '../../models/merchant.model';
import { OptionItem } from '../../models/option.item.model';
import { ProgressModel } from '../../models/progress.model';
import { BankAccount, TerminalTransactionDtoOut, TransactionDetail, TransactionDtoIn } from '../../models/transaction.dto';
import { AzureService } from '../../services/azureServiceBus.services';
import { CounterService } from '../../services/counter.service';
import { CourseWidgetService } from '../../services/courseWidget.service';
import { DetailChangeEventListener } from '../../services/detail.update';
import { MerchantService } from '../../services/merchant.service';
import { RestService } from '../../services/rest.service';
import { validateBankAccount, ValidateBankAccountInject } from '../../validators/bankAccount.validator';
import { } from '@angular/platform-browser'
import { IAuthDto } from '../../models/authDto.model';
import { AuthorizationState } from '../../enums/authorizationState.enum';
import { promise } from 'protractor';

@Component({
    selector: 'terminal-form',
    templateUrl: './terminalForm.component.html',
    styleUrls: ['./terminalForm.component.scss']
})
export class TerminalFormComponent implements OnInit, OnDestroy {

    public BankCodeIsValid: boolean = false;
    private BaSub: Subscription;
    public BankCodes: Array<OptionItem> = [
        {
            name: $localize`České banky`,
            isDisabled: true,
            isSelected: true,
        } as OptionItem
    ];
    private MerchantSubs: Subscription;
    private AlzaTestEnv: IEnvironment = {
        security: {
            privateKey: "hsx8Q~GK86wvbPICHwEixyfeUDmFO1qegKDE9aQ0",
            identity: {
                clientId: "a136ed2d-bd40-4c3a-be1f-75b5cdb6738c",
                scope: "client"
            }
        }
    } as IEnvironment;

    @Output() public OnFormChanged = new EventEmitter<UntypedFormGroup>();
    @Output() public AuthorizationRequired = new EventEmitter<IAuthDto>();

    @Input({ transform: numberAttribute }) public page: number = 0;
    @Input() public progressBar: ProgressModel = null;
    @Input() public progressBarColor: ThemePalette = null;
    @Input() public merchantInfo: MerchantInfoModel = null;
    public showEditAmount: boolean = true;

    public fiatSelection: FiatCurrencyDtoOut[] = [{} as FiatCurrencyDtoOut];
    public selection: CurrencyDtoOut[] = null;
    public selectionDouble: CurrencyDtoOut[] = null;
    public _showEdit: boolean = false;
    public _finalCourse: TerminalTransactionDtoOut = null;
    public _txHasStorno: boolean = false;
    public _azureTx: Subscription = null;
    public _azureTxMerchant: Subscription = null;
    private _merchantModel: MerchantModel = null;

    public tForm: UntypedFormGroup = new UntypedFormGroup({
        inAmount: new UntypedFormControl(null, {
            validators: [
                Validators.required
            ]
        }),
        email: new UntypedFormControl(null, {
            validators: [
                Validators.required,
                Validators.email
            ]
        }),
        inCurrency: new UntypedFormControl('2', {
            validators: [
                Validators.required
            ]
        }),
        inCryptoCurrency: new UntypedFormControl(''),
        //#region Bank account
        bankSuffix: new UntypedFormControl(),
        bankPrefix: new UntypedFormControl(),
        bankAccount: new UntypedFormControl(),
        bankFullAccount: new UntypedFormControl(null, {
            validators: [
                Validators.required
            ],
            asyncValidators: [
                this._bankAccountValidator.validate.bind(this._bankAccountValidator)
            ]
        }),
        //#endregion
        message: new UntypedFormControl(),
        vs: new UntypedFormControl(null, {
            validators: [
                //Validators.pattern('(\d+)')
            ]
        }),
        ss: new UntypedFormControl(),
        termsAndConditions: new UntypedFormControl(false, {
            validators: [
                Validators.requiredTrue
            ]
        })
    });

    constructor(
        @Inject(DOCUMENT) private _document: Document,
        private _timer: CounterService,
        private _rest: RestService,
        private _toast: MatSnackBar,
        private _router: Router,
        private _activeRoute: ActivatedRoute,
        private _azure: AzureService,
        private _merchant: MerchantService,
        private _ngZone: NgZone,
        private _course: CourseWidgetService,
        private _detail: DetailChangeEventListener,
        @Inject(LOCALE_ID) private _locale: string,
        private _bankAccountValidator: ValidateBankAccountInject
    ) {

        this._rest.GetFiatCurrencies().toPromise().then(r => this.fiatSelection = r);
        
        this.BaSub = this._bankAccountValidator.BankAccountValidResult.subscribe(m => {

            this.BankCodeIsValid = m?.isValid;

            let bankSuffix = this.tForm.controls["bankSuffix"];
            bankSuffix.patchValue(m?.suffix);
            bankSuffix.disable();

            let bankPrefix = this.tForm.controls["bankPrefix"];
            bankPrefix.patchValue(m?.prefix);
            bankPrefix.disable();

            let account = this.tForm.controls["bankAccount"];
            account.patchValue(m?.account);
            account.disable();
        });

    }


    /**
     * After leave page should be all subscriptions listening transactions destroyed
     */
    ngOnDestroy(): void {
        this.BaSub?.unsubscribe();
        this._azureTx?.unsubscribe();
        this._azureTxMerchant?.unsubscribe();
        this.MerchantSubs?.unsubscribe();
    }

    ngOnInit(): void {

        this._activeRoute.queryParams.subscribe(query => {
            if (query !== null && query['page'] !== undefined) {
                this.page = query['page'];
            }
        });

        this._merchant.page = this.page;
        this.MerchantSubs = this._merchant.isMerchant.subscribe(async (m) => {

            if (m != null && m.isMerchant === true) {
                this.tForm.controls["inAmount"].setValue(m.transaction.youReceive);
                this.tForm.controls["inCurrency"].setValue(m.transaction.inCurrency);
                let result = await this.LoadCryptoCurrenices(true);
                if (result) {
                    this.openPage(1);
                }
            }

            if (m !== null) {
                this._course.SetCourse({
                    amount: m.transaction.youReceive,
                    cryptoAmount: m.transaction.youSend,
                    cryptoCurrency: m.transaction.youSendCurrencyShortName,
                    currecny: m.transaction.youReceiveCurrencyShortName
                } as CourseWidget);
            }

            if (m?.isMerchant) {
                this.page = 3;
                this.showEditAmount = false;
                this._merchantModel = m;
                //#region Fill form
                this.tForm.controls["inAmount"].setValue(m.transaction.youReceive);
                this.tForm.controls["email"].setValue(m.transaction.email);
                this.tForm.controls["inCurrency"].setValue(m.transaction.inCurrency);
                this.tForm.controls["inCryptoCurrency"].setValue(m.transaction.inCryptoCurrency);
                //console.log(m);
                //#endregion
                if (m.transaction.selectedCryptoCurrency === false) {
                    this.page = 1;
                } else {
                    this.currencySelected(m.transaction.inCryptoCurrency, true, m.transaction)
                }
            } else {
                this.page = 0;
            }

        });

        this.LoadBankCodes();

        this.tForm.valueChanges.subscribe(m => {
            if (m !== null) {
                this.OnFormChanged.emit(m);
            }
        });
    }

    /**
     * Method loading cryptocurrencies from backend on inicialization part
     * @param jumper This argument has responsible to show Form page / show CryptoCurrency selection after currencies are loaded
     */
    private LoadCryptoCurrenices = async (jumper: boolean = false): Promise<boolean> => {
        try {
            let r = await this.CurrenciesLoader();
            if (r != void 0) {
                let anyAvaliable = r.filter(m => m.isEnable).length > 0;
                // Refresh currencies
                this.selectionDouble = r.filter(m => m.shortName.indexOf('BTC') !== -1);
                this.selection = r.filter(m => m.shortName.indexOf('BTC') == -1);

                if (!anyAvaliable) {
                    //this.AuthorizationRequired.emit(({email: this.tForm.get("email")?.value, requested: true}));
                    return await this.ValidateRequest(this.tForm.get("email")?.value);
                } else {
                    return true;
                }
            } else{
                return false;
            }

        } catch (err) {
            console.log(err);
            this._toast.open($localize`Chyba v načtení měn pro převod prostředků.`);
            return false;
        }
    }

    private ValidateRequest = async (email): Promise<boolean> => {
        let response = await this._rest.GetFormAuthorization(email).toPromise();

        let dto = {
            email: email,
            message: response.value?.message
        } as IAuthDto;

        if (response.value === null) {
            this._toast.open($localize`Pro tuto kombinaci není dostupná, žádná měna ke směně. Je třeba se autorizovat vůči naší službě.`, $localize`Zavřít`, {
                duration: 5000
            });
            dto.requested = true;
            this.AuthorizationRequired.emit(dto);
            console.log(dto);
            return false;
        }

        dto.state = response.value?.authorizationState;

        if (response.value.authorizationState != AuthorizationState.APPROVED) {
            dto.requested = true;
            this.AuthorizationRequired.emit(dto);
            console.log(dto);
            return false;
        } else {
            return true;
        }
    }

    private CurrenciesLoader = async (): Promise<CurrencyDtoOut[] | null> => {

        var r = await this._rest.GetFormAuthorization(this.tForm.controls['email']?.value).toPromise();

        if (r !== null) {
            console.log(r);
            return this._rest.GetCurrencies({
                inAmount: this.tForm.controls['inAmount']?.value,
                inCurrency: parseInt(this.tForm.controls['inCurrency']?.value),
                user: 1,
                userEmail: this.tForm.controls['email']?.value
            }).toPromise();
        } else{
            return null;
        }
    }

    private LoadBankCodes = () => this._rest.GetBanksInformations().toPromise().then(r => {
        if (r !== null) {
            r.value.forEach(item => {
                this.BankCodes.push(
                    {
                        name: item.name,
                        isDisabled: false,
                        isSelected: false,
                        value: item.suffix
                    } as OptionItem)
            });
        }
    })

    public accNewEdit() {
        this.tForm.get('bankFullAccount').patchValue(null);
        this.BankCodeIsValid = false;
    }

    /**
     * Submit form on page 1
     * @param f form component result
     */
    public Submited = async (f: NgForm): Promise<void> => {

        if (f.form.value.vs !== null && f.form.value.vs.length > 0) {
            //this.AuthorizationRequired.emit(({email: this.tForm.get("email")?.value, requested: true}));
            let result = await this.ValidateRequest(this.tForm.get("email")?.value);
            if (!result) {
                return;
            }
        }

        this.validateBankAccount().then(async (r) => {
            if (f.form.valid && r.value.isValid) {
                let result = await this.LoadCryptoCurrenices(true);
                if (result) {
                    this.openPage(1);
                }
            } else {
                this._toast.open($localize`Číslo bankovního účtu není platné!`)
            }
        }).catch(err => console.error(err));
    }

    /**
     * Currency has bean chosen on page 2
     * @param cryptoCurrency Cryptocurrency BTC/ETH etc.
     * @param useAlzaConfig Testing for Alza company
     */
    public currencySelected = (cryptoCurrency: number, useAlzaConfig: boolean = false, marchantModel: TransactionDetail = null) => {

        if (marchantModel === null) {

            // Selected crypto currency
            this.tForm.controls['inCryptoCurrency'].setValue(cryptoCurrency);
            var any = this.selection.some(m => m.inCryptoCurrency == cryptoCurrency);

            var mappedChoice: CurrencyDtoOut;
            //#region Watch that chosen currency is exists in both collections
            // If not, toast will be open
            if (this.selection.some(m => m.inCryptoCurrency == cryptoCurrency)) {
                mappedChoice = this.selection.filter(m => m.inCryptoCurrency == cryptoCurrency)[0];
            } else if (this.selectionDouble.some(m => m.inCryptoCurrency == cryptoCurrency)) {
                mappedChoice = this.selectionDouble.filter(m => m.inCryptoCurrency == cryptoCurrency)[0];
            } else {
                this._toast.open($localize`Nenašli jsme požadovaný výběr.`);
            }
            //#endregion

            // Update FE currency course
            this._course.SetCourse({
                amount: mappedChoice.inAmount,
                cryptoAmount: mappedChoice.cryptoAmount,
                cryptoCurrency: mappedChoice.inCryptoCurrencyName,
                currecny: this.fiatSelection[mappedChoice.inCurrency].currency.currencyCode
            } as CourseWidget);
        }

        /**
         * Model used for all transactions witch are not supouse to be merchant
         */
        if (this._merchantModel === null || !this._merchantModel.isMerchant) {
            this._toast.open($localize`Vteřinku, pracuji...`);
            // Create transaction and get QR-code
            let txModel = {
                bankAccountNumber: this.getBankAccountString({
                    account: this.tForm.get("bankAccount").value,
                    prefix: this.tForm.get("bankPrefix").value,
                    suffix: this.tForm.get("bankSuffix").value
                } as BankAccount),
                inAmount: parseFloat(this.tForm.get("inAmount").value),
                terminalTransactionType: 1,
                inCryptoCurrency: parseInt(this.tForm.get("inCryptoCurrency").value),
                inCurrency: parseInt(this.tForm.get("inCurrency").value),
                message: this.tForm.get("message").value,
                terminalKey: "123456789",
                userName: this.tForm.get("email").value,
                x_ss: this.tForm.get("ss").value,
                x_vs: this.tForm.get("vs").value,
                clientId: useAlzaConfig ? this.AlzaTestEnv.security.identity.clientId : environment.security.identity.clientId
            } as TransactionDtoIn;

            this._rest.PostTransaction(txModel).toPromise()
            .catch(err=>console.error(err))
            .then(async(r) =>{
                //#region Azure service
                if(typeof r == "object"){
                    this._azure.JoinGroup(r.transactionID.toString());
                    this._azureTx = this._azure.Transactions.subscribe((m) => {
                        if(!this.merchantInfo){
                            this._rest.GetTransactionByHash(r.transactionID.toString()).toPromise().then((tr) => {

                                // show merchant info
                                this.merchantInfo = tr.merchantInfo;
    
                                if (tr.status > 2) {
                                    this._detail.setTopBarSteps(3);
                                    this.redirectToAction(tr.transactionReverseID.toString(), 'invoice');
                                }
    
                                this.redirectToQrCode(r);
                                
                            }, (err) => console.error(err))
                        }
                    });
                }
                
                //#endregion
            });
        } else // Supose to be Merchant transaction
        {
            this._toast.open($localize`Otevírám detail platby`);
            //#region Azure service
            this._azure.JoinGroup(this._merchantModel.transaction.transactionID.toString());
            this._azureTxMerchant = this._azure.Transactions.subscribe(m => {
                
                if(m=null){
                    return;
                };

                this._rest.GetTransactionByHash(this._merchantModel.transaction.transactionReverseID.toString()).toPromise().then((tr) => {
                    this.merchantInfo = tr.merchantInfo;

                    if (tr.status > 2) {
                        this._detail.setTopBarSteps(4);
                        this.redirectToAction(this._merchantModel.transaction.transactionReverseID.toString(), 'invoice');
                    }
                }, (err) => console.log(err));

            });
            //#endregion

            /**
             * Update selected currency
             */
            this._rest.PostSelectedTransaction({
                currency: cryptoCurrency,
                txHash: this._merchantModel.transactionId
            }).subscribe((r) => {

                this.showQrCode(r);
                
            },(err) => console.error(err));
        }
    }

    public showQrCode = (r: TerminalTransactionDtoOut) => {
        
        // Set steper on QR COde
        this._detail.setTopBarSteps(2);
        this.page = 2;
        this._finalCourse = r;
        this._toast.dismiss();
        this._timer.Start();

        this._timer.WatchTime.subscribe(m => {
            this._txHasStorno = m.storno;

            this.progressBar = {
                percent: m.percent,
                show: m.show,
                storno: m.storno,
                time: new Date(m.time)
            } as ProgressModel;

            if (m.storno) {
                this._finalCourse.qrCodeImage = null;
                this._finalCourse.address = null;
                this.tForm.controls["inAmount"].setValue(null);
                this.tForm.controls["inCryptoCurrency"].setValue(null);
            }
        });
        //#endregion
    }

    public redirectToQrCode = (r: TerminalTransactionDtoOut) => this._router.navigate(["", r.reverseTXID],{
        queryParams: {
            page: 2
        },
        relativeTo: this._activeRoute,
        skipLocationChange: false 
    });

    public stopProp = (e: Event) => {
        e.stopPropagation();
    }

    public showEdit = async (recalculate: boolean = false) => {
        if (recalculate == true) {
            let result = await this.LoadCryptoCurrenices(true);
            if (result) {
                this.openPage(1);
            }
        }
        this._showEdit = !this._showEdit;
    }

    public getBankAccountString(account: BankAccount): string {
        if (account.prefix != undefined || account.prefix != null) {
            return `${account.prefix}-${account.account}/${account.suffix}`;
        } else {
            return `${account.account}/${account.suffix}`;
        }
    }

    public redirectToAction(txid: string, path: string): void {
        this._ngZone.run(() => {
            this._router.navigate([path, txid]);
        });
    }
    /**
     * URI-scheme
     * @example bitcoin:n4ijQMT8NtB58dZrgodiMMmjFZh4KyKEG3
     *          ?amount=0.00062
     *          &label=Starbucks
     *          &message=Cappuccino
     * @see {@link https://cypherpunk.org/2019/11/02/a-look-at-cryptocurrency-uris/}
     * @see {@link https://satoshi.nakamotoinstitute.org/posts/bitcointalk/threads/30/}
     */
    public openInWallet() {
        this._document.location.href = this._finalCourse?.buttonLink;
    }

    /**
     * Action for switch content
     * @param pagenumber Number of page to show
     */
    public openPage(pagenumber: number) {
        this.page = pagenumber;
        if (pagenumber !== 0) {
            this._router.navigate([], {
                queryParamsHandling: 'merge',
                queryParams: {
                    page: pagenumber
                }
            });
        }
    }

    getPageNumber = (value: any) => value.toString();

    getObjectType = (obj: any): string => obj ? obj.constructor.name : 'undefined';

    public cancelTransaction = (event:Event) =>{
        event.preventDefault();
        event.stopPropagation();

        this._rest.PutTransaction(this._merchantModel?.transaction?.transactionID, 1).toPromise()
        .then(()=>this._document.location.reload())
        .catch((err) => console.error(err));
    }

    public createMerchantTx = () => {
        let enClone = environment.security;
        environment.security.privateKey = this.AlzaTestEnv.security.privateKey;
        environment.security.identity.clientId = this.AlzaTestEnv.security.identity.clientId;
        environment.security.identity.scope = this.AlzaTestEnv.security.identity.scope;
        this.currencySelected(1, true);
        environment.security = enClone;
    };

    /**
     * Validate bank account number
     */
    private validateBankAccount = async () => {

        var acc = "";

        if (this.tForm.controls["bankPrefix"].value !== null && this.tForm.controls["bankPrefix"] !== undefined) {
            acc = `${this.tForm.controls["bankPrefix"].value}-${this.tForm.controls["bankAccount"].value}/${this.tForm.controls["bankSuffix"].value}`;
        } else {
            acc = `${this.tForm.controls["bankAccount"].value}/${this.tForm.controls["bankSuffix"].value}`;
        }

        return await this._rest.GetBankAccountInfo({
            bankNumber: acc,
            culture: this._locale
        } as IBankAccount).toPromise()
    }
}
